import { Injectable } from '@angular/core';
import { DocumentsIndicatorsDto } from '../_modelsDTO/indicators/DocumentsIndicatorsDto';
import { AuthService } from './auth.service';
import { TasksIndicatorsDto } from '../_modelsDTO/indicators/TasksIndicatorsDto';
import { ReworksIndicatorsDto } from '../_modelsDTO/indicators/ReworksIndicatorsDto';
import { RulesIndicatorsDto } from '../_modelsDTO/indicators/RulesIndicatorsDto';
import { ImgComparesIndicatorsDto } from '../_modelsDTO/indicators/ImgComparesIndicatorsDto';
import { SharedDocsIndicatorsDto } from '../_modelsDTO/indicators/SharedDocsIndicatorsDto';
import { TaskAvgTimesIndicatorsDto } from '../_modelsDTO/indicators/TaskAvgTimesIndicatorsDto';
import { TasksByStatusIndicatorsDto } from '../_modelsDTO/indicators/TasksByStatusIndicatorsDto';
import { DocumentsByStatusIndicatorDto } from '../_modelsDTO/indicators/DocumentsByStatusIndicatorDto';
import { IndicatorData } from '../_modelsDTO/indicators/IndicatorData';

@Injectable({
  providedIn: 'root'
})
export class IndicatorService
{

  constructor(
    private authService: AuthService
  )
  { }

  async getDocumentsIndicators(from?: Date, to?: Date): Promise<DocumentsIndicatorsDto>
  {
    const companyId = this.authService.getCurrentIdentity()?.company?.id;
    if (!companyId)
    {
      throw new Error('Company Id not found');
    }

    const params: [string, string][] = [
      ['companyId', companyId],
      ['from', from?.toISOString()],
      ['to', to?.toISOString()]
    ];

    const result = await this.authService.authGet<DocumentsIndicatorsDto>('/api/v2/indicators/getDocumentsIndicators', params);
    return result;
  }

  async getTasksIndicators(from?: Date, to?: Date): Promise<TasksIndicatorsDto>
  {
    const companyId = this.authService.getCurrentIdentity()?.company?.id;
    if (!companyId)
    {
      throw new Error('Company Id not found');
    }

    const params: [string, string][] = [
      ['companyId', companyId],
      ['from', from?.toISOString()],
      ['to', to?.toISOString()]
    ];

    const result = await this.authService.authGet<TasksIndicatorsDto>('/api/v2/indicators/getTasksIndicators', params);
    return result;
  }

  async getImgComparesIndicators(from?: Date, to?: Date): Promise<ImgComparesIndicatorsDto>
  {
    const companyId = this.authService.getCurrentIdentity()?.company?.id;
    if (!companyId)
    {
      throw new Error('Company Id not found');
    }

    const params: [string, string][] = [
      ['companyId', companyId],
      ['from', from?.toISOString()],
      ['to', to?.toISOString()]
    ];

    const result = await this.authService.authGet<ImgComparesIndicatorsDto>('/api/v2/indicators/getImgComparesIndicators', params);
    return result;
  }

  async getSharedDocsIndicators(from?: Date, to?: Date): Promise<SharedDocsIndicatorsDto>
  {
    const companyId = this.authService.getCurrentIdentity()?.company?.id;
    if (!companyId)
    {
      throw new Error('Company Id not found');
    }

    const params: [string, string][] = [
      ['companyId', companyId],
      ['from', from?.toISOString()],
      ['to', to?.toISOString()]
    ];

    const result = await this.authService.authGet<SharedDocsIndicatorsDto>('/api/v2/indicators/getSharedDocsIndicators', params);
    return result;
  }

  async getRulesIndicators(from?: Date, to?: Date): Promise<RulesIndicatorsDto>
  {
    const companyId = this.authService.getCurrentIdentity()?.company?.id;
    if (!companyId)
    {
      throw new Error('Company Id not found');
    }

    const params: [string, string][] = [
      ['companyId', companyId],
      ['from', from?.toISOString()],
      ['to', to?.toISOString()]
    ];

    const result = await this.authService.authGet<RulesIndicatorsDto>('/api/v2/indicators/getRulesIndicators', params);
    return result;
  }

  async getTaskAvgTimesIndicators(from?: Date, to?: Date, includeOpenTasks?: boolean): Promise<TaskAvgTimesIndicatorsDto>
  {
    const companyId = this.authService.getCurrentIdentity()?.company?.id;
    if (!companyId)
    {
      throw new Error('Company Id not found');
    }

    const params: [string, string][] = [
      ['companyId', companyId],
      ['from', from?.toISOString()],
      ['to', to?.toISOString()],
      ['includeOpenTasks', includeOpenTasks?.toString()]
    ];

    const result = await this.authService.authGet<TaskAvgTimesIndicatorsDto>('/api/v2/indicators/getTaskAvgTimesIndicators', params);
    return result;
  }

  async getTasksByStatusIndicators(from?: Date, to?: Date): Promise<TasksByStatusIndicatorsDto>
  {
    const companyId = this.authService.getCurrentIdentity()?.company?.id;
    if (!companyId)
    {
      throw new Error('Company Id not found');
    }

    const params: [string, string][] = [
      ['companyId', companyId],
      ['from', from?.toISOString()],
      ['to', to?.toISOString()]
    ];

    const result = await this.authService.authGet<TasksByStatusIndicatorsDto>('/api/v2/indicators/getTasksByStatusIndicators', params);
    return result;
  }

  async getDocumentsByStatusIndicators(from?: Date, to?: Date): Promise<DocumentsByStatusIndicatorDto>
  {
    const companyId = this.authService.getCurrentIdentity()?.company?.id;
    if (!companyId)
    {
      throw new Error('Company Id not found');
    }

    const params: [string, string][] = [
      ['companyId', companyId],
      ['from', from?.toISOString()],
      ['to', to?.toISOString()]
    ];

    const result = await this.authService.authGet<DocumentsByStatusIndicatorDto>(
      '/api/v2/indicators/getDocumentsByStatusIndicators', params);
    return result;
  }

  async getReworksIndicators(from?: Date, to?: Date): Promise<ReworksIndicatorsDto>
  {
    const companyId = this.authService.getCurrentIdentity()?.company?.id;
    if (!companyId)
    {
      throw new Error('Company Id not found');
    }

    const params: [string, string][] = [
      ['companyId', companyId],
      ['from', from?.toISOString()],
      ['to', to?.toISOString()]
    ];

    const result = await this.authService.authGet<ReworksIndicatorsDto>(
      '/api/v2/indicators/getReworksIndicators', params);
    return result;
  }

  async getPendingTasksCountByReviewer(from?: Date, to?: Date): Promise<IndicatorData[]>
  {
    const companyId = this.authService.getCurrentIdentity()?.company?.id;
    if (!companyId)
    {
      throw new Error('Company Id not found');
    }

    const params: [string, string][] = [
      ['companyId', companyId],
      ['from', from?.toISOString()],
      ['to', to?.toISOString()]
    ];

    const result = await this.authService.authGet<IndicatorData[]>(
      '/api/v2/indicators/getPendingTasksCountByReviewer', params);
    return result;
  }
}
