<div class="sidenav-content">

  <div class="shared">

    <div class="close">
      <mat-icon class="notranslate" (click)="closeSideNav()">close</mat-icon>
    </div>

    <div class="sidenav-topmenu">
      <mat-icon class="notranslate"
                [ngClass]="{'selected': showProfile}" 
                *ngIf="identityLoaded"
                matTooltip="Profile"
                matTooltipPosition="above"
                (click)="toggleProfile()">
                account_circle
      </mat-icon>
      <mat-icon class="notranslate"
                *ngIf="showSettings"
                matTooltip="Settings"
                matTooltipPosition="above"
                (click)="settings()">
                settings
      </mat-icon>
      <mat-icon class="notranslate"
                matTooltip="User Manual"
                matTooltipPosition="above"
                (click)="tutorial()">
                help
      </mat-icon>
      <!--mat-icon class="notranslate" 
                name="support"
                *ngIf="identityLoaded"
                matTooltip="Contact support"
                matTooltipPosition="above"
                (click)="openSupport()">
                headset_mic
      </mat-icon-->
      <span class="logout" 
            name='logout' 
            *ngIf="identityLoaded" 
            (click)="logout()">
            Logout
      </span>
    </div>

    <div class="sidenav-user">
      <identity [showIcons]="showIcons"></identity>
    </div>

    <div class="profile" *ngIf="showProfile && identity.user">
      <app-profile [user]="identity.user"></app-profile>
    </div>

  </div>

  <div class="web-only" *ngIf="identityLoaded && identity.user.isSuperUser">
    <div class="sidenav-text-menu" *ngFor="let menuItem of menuItems" (click)="actionClicked(menuItem)">
      <button [ngClass]="{'selected': menuItem.url == currentUrl}">{{menuItem.label}}</button>
    </div>
  </div>

  <div class="version">
    <div *ngIf="frontendInfo">Container {{frontendInfo.containerVersion}},</div>
    <div *ngIf="backendInfo">Server {{backendInfo.cloudName}} {{backendInfo.version}}</div>
    <div *ngIf="frontendInfo">Extension {{frontendInfo.frontendVersion}} - <span class="update-button" (click)="forceUpdate()">Force Update</span>,</div>
  </div>

</div>
