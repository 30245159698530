import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { FilesystemService, PlatformFont } from 'src/app/services/filesystem.service';
import {map, startWith} from 'rxjs/operators';
import { IllustratorConfigService } from 'src/app/services/illustrator-config.service';

@Component({
    selector: 'app-fonts-picker',
    templateUrl: './fonts-picker.component.html',
    styleUrls: ['./fonts-picker.component.scss'],
    standalone: false
})
export class FontsPickerComponent implements OnInit {

  fontPickerControl = new UntypedFormControl('');
  options: PlatformFont[] = [];
  filteredOptions: Observable<PlatformFont[]>;

  // selectedFont: PlatformFont;

  constructor(
    public dialogRef: MatDialogRef<FontsPickerComponent>,
    private filesystem: FilesystemService,
    private configService: IllustratorConfigService)
  { }

  ngOnInit(): void
  {
    this.loadPlatformFonts();
    this.filteredOptions = this.fontPickerControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  private async loadPlatformFonts()
  {
    const config = await this.configService.getConfiguration();
    this.options = await this.filesystem.getPlatformFonts(config.fontDirectories);
  }

  private _filter(value: string): PlatformFont[]
  {
    const filterValue = value.toLowerCase();
    return this.options?.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  cancel()
  {
    this.dialogRef.close();
  }

  ok(font: PlatformFont)
  {
    this.dialogRef.close(font);
  }

}
