import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { TextDifference } from '../_modelsDTO/textCompare/TextDifference';

interface TextDifferencesPostData
{
  textA: string;
  textB: string;
}

interface FindFuzzyTextPostData
{
  fuzzyText: string;
  fullText: string;
}

@Injectable({
  providedIn: 'root'
})
export class TextCompareService {

  constructor(private authService: AuthService)
  {}

  async getDifferences(textA: string, textB: string): Promise<TextDifference[]>
  {
    const postData: TextDifferencesPostData =
    {
      textA: textA,
      textB: textB
    };
    const result = await this.authService.authPost<TextDifference[]>(
      '/api/v2/textCompare/getDifferences', postData);
    return result;
  }

  async getDifferencesHtml(textA: string, textB: string): Promise<string>
  {
    const postData: TextDifferencesPostData =
    {
      textA: textA,
      textB: textB
    };

    const result = await this.authService.authPost<string>(
      '/api/v2/textCompare/getDifferencesHtml', postData);
    return result;
  }

  async findFuzzyText(fuzzyText: string, fullText: string): Promise<string>
  {
    const postData: FindFuzzyTextPostData =
    {
      fuzzyText: fuzzyText,
      fullText: fullText
    };

    const result = await this.authService.authPost<string>(
      '/api/v2/textCompare/findFuzzyText', postData);
    return result;
  }

}
